<template>
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <ellipse cx="36.3784" cy="33" rx="33.4595" ry="33" fill="white" />
    </g>
    <path
      d="M29.281 23.6578C29.281 22.0766 31.0293 21.1208 32.3604 21.9741L46.9331 31.3163C48.1606 32.1032 48.1606 33.8968 46.9331 34.6837L32.3604 44.0259C31.0292 44.8792 29.281 43.9234 29.281 42.3422V23.6578Z"
      :fill="color"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.192404"
        y="0"
        width="72.3721"
        height="71.923"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="3.19648" />
        <feGaussianBlur stdDeviation="1.36327" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.325 0 0 0 0 0.325 0 0 0 0 0.325 0 0 0 0.0727007 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PlayIcon",
  props: {
    color: {
      type: String,
      default: '#B503D0'
    }
  }
};
</script>
