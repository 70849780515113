<template>
  <div class="grid grid-cols-5 gap-5 mb-10">
    <template v-for="(link, socialNetwork) in socialLinks">
      <a v-if="link" :href="link" :key="link" target="_blank" class="w-10 h-10 rounded-lg flex items-center justify-center hover:shadow-lg" :style="backgroundColor(socialNetwork)">
        <component :is="icon(socialNetwork)" :style="fillColor(socialNetwork)" :withGradient="true"/>
      </a>
    </template>
  </div>
</template>

<script>
import {startCase} from 'lodash';
import FacebookIcon from "@/components/svgs/SocialNetworks/FacebookIcon";
import TwitterIcon from "@/components/svgs/SocialNetworks/TwitterIcon";
import InstagramIcon from "@/components/svgs/SocialNetworks/InstagramIcon";
import LinkedinIcon from "@/components/svgs/SocialNetworks/LinkedinIcon";
import YoutubeIcon from "@/components/svgs/SocialNetworks/YoutubeIcon";
import WebsiteIcon from "@/components/svgs/SocialNetworks/WebsiteIcon";
import WikipediaIcon from "@/components/svgs/SocialNetworks/WikipediaIcon";
import TiktokIcon from "@/components/svgs/SocialNetworks/TiktokIcon";
import SocialIconLink from '@/components/shared/SocialIconLink'

export default {
  name: "SocialLinks",
  components: {
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    YoutubeIcon,
    WebsiteIcon,
    WikipediaIcon,
    TiktokIcon,
    SocialIconLink
  },
  props: {
    socialLinks: [Object, Array],
    type: { type: String, default: 'guest' }
  },

  methods: {
    icon(socialNetwork) {
      return `${startCase(socialNetwork)}Icon`
    },

    backgroundColor(socialNetwork) {
      switch (socialNetwork) {
        case 'facebook':
          return 'background: rgba(87, 139, 248, 0.21);';
        case 'twitter':
          return 'background: rgba(29, 161, 242, 0.21);';
        case 'instagram':
          return 'background: rgba(194, 194, 194, 0.21);';
        case 'linkedin':
          return 'background: rgba(18, 129, 191, 0.24);';
        case 'youtube':
          return 'background: rgba(255, 0, 0, 0.19);';
        case 'website':
          return 'background: rgba(41, 37, 70, 0.1);';
        case 'wikipedia':
          return 'background: rgba(179, 179, 179, 0.1);';
        case 'tiktok':
          return 'background: rgba(194, 194, 194, 0.21);';
        default:
          break;
      }
    },

    fillColor(socialNetwork) {
      switch (socialNetwork) {
        case 'facebook':
          return 'color: #3C5A99';
        case 'twitter':
          return 'color: #1DA1F2';
        case 'linkedin':
          return 'color: #0274B3';
        case 'youtube':
          return 'color: #FF0000';
        case 'website':
          return 'color: #292546';
        case 'wikipedia':
          return 'color: #000000';
        case 'tiktok':
          return 'color: #000000';
        default:
          break;
      }
    }
  },
};
</script>
