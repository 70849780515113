<template>
  <svg class="h-5 w-5 stroke-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="current" clip-rule="evenodd" d="M14.8881 10.5436C11.4441 10.5436 8.37905 11.7376 6.90405 14.9996V13.9996C6.90405 9.03157 10.5261 6.04457 14.8881 6.04457V3.88657C14.8881 3.13157 15.7711 2.72257 16.3471 3.21157L21.6871 7.74457C22.0951 8.08957 22.1061 8.71657 21.7091 9.07557L16.3691 13.9166C15.8001 14.4316 14.8881 14.0286 14.8881 13.2606V10.5436Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V15"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
name:'ShareIcon'
}
</script>

<style>

</style>