<template>
  <footer class="w-full bg-black text-white px-10 pt-12 pb-8 lg:px-20 grid justify-items-center">
    <div class="flex-row md:flex justify-center align-middle items-center mb-13 w-12/12 lg:w-9/12 -ml-1 lg:-ml-24">
        <div class="mb-4 lg:mb-0 flex w-full md:w-6/12 lg:w-4/12">
           <Logo/>
          <h2 class=" text-2xl w-full md:w-56 font-bold ">Create your own free press kit! </h2>
        </div>
        <span class="hidden lg:block border-solid border-r-2 h-10 mr-8"></span>
        <div class="text-center lg:text-left w-full md:w-3/12">
          <span class="text-sm">Sign up for your Guestio account and start getting booked today</span>
        </div>
    </div>

    <div class="flex mb-12 md:mb-0 justify-between w-full lg:w-8/12">
      <div class="grid justify-items-center">
        <h3 class=" font-bold text-xl mb-6">Guests</h3>
        <a href="/browse?showing=guests"><p class=" font-light text-sm">Find a Guest</p></a>
        <a href="/register/"><p class="font-light text-sm">Become a Guest</p></a>
      </div>
      <div>
        <a href="/register/"><input value="Start now" type="button" class=" hidden md:block bg-indigo-gradiant h-14 rounded-full px-10 text-lg font-semibold"/></a>    
      </div>
      <div class="grid justify-items-center">
        <h3 class="font-bold text-xl mb-6">Shows</h3>
         <a href="/browse?showing=shows"><p class=" font-light text-sm">Find a Show</p></a>
         <a href="/register/"><p class=" font-light text-sm">Claim your Show Page</p></a>
      </div>
    </div>
    <div>
        <a href="/register/"><input value="Start now" type="button" class=" md:hidden block bg-indigo-gradiant h-14 rounded-full px-10 text-lg font-semibold"/></a>    
      </div>
  </footer>
</template>


<script>
import Logo from "@/components/svgs/Logo";
export default {
  name: "HowGuestioWorks",
  components: {
    Logo,
  },
};
</script>

