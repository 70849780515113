<template>
  <div
    class="w-full p-4 shadow-md bg-white flex flex-col lg:flex-row rounded-xl items-center">
    <div class="flex flex-wrap lg:flex-no-wrap flex-grow items-center w-full">
      <img class="h-32 lg:h-auto w-full object-cover rounded-md mb-2 lg:mb-0 lg:mr-5"
           :class="isEpisode ? 'lg:w-24': 'lg:w-32'" :src="isFile ? '/images/podcast_link.png' : podcast.imageUrl" alt="image"/>
      <div v-if="isEpisode" class="flex flex-col md:flex-row justify-between w-full">
        <div class="flex flex-col lg:mr-10 mb-5 lg:mb-0">
          <h4 class="font-semibold text-sm uppercase" :style="textColor">{{ podcast.podcastTitle }}</h4>
          <span class="text-black font-semibold text-lg">{{ podcast.episodeTitle | truncate(35) }}</span>
          <span class="text-gray-500 mt-1 text-sm">{{ podcast.episodeDescription | truncate(100) }}</span>
        </div>
        <div class="flex flex-col items-center hidden md:block">
          <a :href="podcast.url" target="_blank">
            <PlayIcon :color="fontColor"/>
          </a>
          <span class="text-gray-500 font-semibold uppercase text-xs">{{ podcast.episodeDuration }}</span>
        </div>
        <a :href="podcast.url" target="_blank" class="rounded-lg text-center text-white py-3 bg-black md:hidden">Play
          Episode</a>
      </div>
      <div v-else-if="isFile" class="flex flex-col">
        <h4 class="font-semibold text-lg text-pink-500">{{ podcast.podcastTitle }}</h4>
        <span class="text-gray-500 text-light mt-1 text-sm">{{ podcast.podcastDescription | truncate(100) }}</span>
        <a
          :href="podcast.url"
          target="_blank"
          class="bg-black text-white rounded-lg w-full lg:w-40 h-10 flex justify-center items-center mt-3">
          Listen Podcast
        </a>
      </div>
      <div v-else class="flex flex-col">
        <h4 class="font-semibold text-lg text-pink-500">{{ podcast.podcastTitle }}</h4>
        <span class="text-gray-500 text-light mt-1 text-sm">{{ podcast.podcastDescription | truncate(100) }}</span>
        <a
          :href="podcast.url"
          target="_blank"
          class="bg-black text-white rounded-lg w-full lg:w-40 h-10 flex justify-center items-center mt-3">
          Visit Podcast
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PlayIcon from "@/components/svgs/PlayIcon";

export default {
  name: "Podcast",
  props: {
    podcast: Object,
    fontColor: {
      type: String,
      default: '#B503D0'
    },
  },
  components: {PlayIcon},

  computed: {
    isEpisode() {
      return this.podcast.linkType == 'episode'
    },

    isFile() {
      return this.podcast.linkType == 'file'
    },

    textColor() {
      return `color: ${this.fontColor}`
    }
  },
};
</script>
