<template>
  <div class="w-full">
    <div class="bg-white rounded-3xl px-6 py-6 shadow-xl" style="min-height:180px">
      <div class="flex items-start justify-between mb-4">
        <div class="flex">
          <div>
            <h4 class="font-bold capitalize">{{from}}</h4>
            <ProfileRating :rating="review.number" :starSize="18" :activeColor="starColor" inactiveColor="#D4D4D4" :padding="2"/>
          </div>
        </div>
        <span class="text-sm opacity-50">{{timeAgo}}</span>
      </div>
      <p class="text-sm opacity-50 italic">"{{review.comment}}"</p>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import ProfileRating from "@/components/shared/ProfileRating";
export default {
  name: 'ReviewCard',
  components: {ProfileRating},
  props: {
    review: Object,
    starColor: {
      type: String,
      default: '#B503D0'
    },
  },
  computed: {
    timeAgo() {
      return moment(this.review.created_at).fromNow();
    },
    from() {
      return this.review.guest ? this.review.guest.name : this.review.show.name
    },
    image() {
      const defaultImage = require('@/assets/images/test_image.png');
      return this.imageUrl ? this.imageUrl : defaultImage
    },
  },
}
</script>
