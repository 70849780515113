<template>
  <div>
    <template v-if="ratings.length">
      <div class="flex justify-between">
        <span class="guest-title">{{reviewsText}}</span>
        <span class="text-violet cursor-pointer" @click="openAllReview">See all</span>
      </div>
      <ProfileRating :rating="avgRating" :starSize="20" :activeColor="starColor" inactiveColor="#D4D4D4" :padding="2" class="mb-3 -mt-2"/>

      <div class="grid grid-cols-1 gap-y-6 mb-6">
        <ReviewCard v-for="(review,index) in ratings.slice(0,3)" :key="index" :review="review" :starColor="starColor"/>
      </div>
    </template>

    <div class="flex justify-between mb-8">
      <router-link
        v-if="canAddReview"
        :to="reviewRoute"
        class="rounded-full font-semibold text-sm shadow-xl px-6 py-3 bg-white inline-block"
        :style="textColor">
          Add your review
      </router-link>
    </div>
     <portal to="modal">
        <div v-show="allReview" class="h-screen py-5 sm:py-32 w-full bg-black bg-opacity-50 modal-container fixed bottom-0 inset-x-0 px-4 inset-0 pb-6 xl:p-0 sm:flex sm:items-center sm:justify-center z-50">
          <button @click="openAllReview">
         <XIcon class=" cursor-pointer h-13 text-white absolute right-0 top-0 mr-8 mt-5 z-60" /> 
        </button>
          <div class="bg-guestio-gray-100 h-130 overflow-x-scrollrounded-lg w-full sm:w-8/12 py-5 px-5 sm:px-16 rounded-3xl">
            <div class="flex sm:flex-row flex-col items-start sm:items-center justify-between mb-5">
              <div>
                <h2 class=" text-blue-800 font-bold text-3xl mb-3">All Reviews</h2>
                <div class="flex items-center space-x-4 mb-4 sm:mb-0">
                  <ProfileRating :rating="avgRating" :starSize="20" :activeColor="starColor" inactiveColor="#D4D4D4" :padding="2" class="mb-1"/>
                  <span class=" text-gray-500 text-sm">{{ratings.length}} Reviews</span>
                </div>
              </div>
              <div class="flex items-center space-x-3">
                <!-- Image-->
              <div :style="'background-image: url('+ guestImage +')'"
                  class="w-12 h-12 rounded-full bg-center bg-cover bg-no-repeat">
              </div>
                <span class=" text-blue-800 font-semibold text-lg">{{profileName}}</span>
              </div>
            </div>

            <div class=" overflow-auto h-128 sm:h-129 py-10 space-y-4 overflow-scroll">
              <ReviewCard v-for="(review,index) in ratings" :key="index" :review="review" :starColor="starColor"/>
            </div>
          </div>
        </div>
      </portal>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import ProfileRating from "@/components/shared/ProfileRating";
import ReviewCard from "@/components/profile/Reviews/partials/ReviewCard";

import XIcon from '@/components/svgs/XIcon';

export default {
  name: 'Reviews',

  components: {ProfileRating, ReviewCard,XIcon},

  data() {
    return {
      allReview: false
    }
  },

  props: {
    ratings: Array,
    avgRating: Number,
    type: String,
    userId: Number,
    profileId: Number,
    starColor: {
      type: String,
      default: '#B503D0'
    },
    profileName:{
      type:String,
      require:true
    },
    profileImg:{
      type:String,
      require:true
    }
  },

  computed: {
    reviewsText() {
      if (! this.ratings) {
        return '0 Reviews'
      }

      return `${this.ratings.length} ${pluralize('Review', this.ratings.length)}`
    },

    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },

    user() {
      return this.$store.getters['auth/getUser']
    },

    canAddReview() {
      if (! this.authenticated || this.user.id == this.userId) {
        return false
      }

      if (this.type == 'guest' && ! this.userHasShows) {
        return false
      }

      if (this.type == 'show' && ! this.userHasGuest) {
        return false
      }

      return true
    },

    userHasShows() {
      return this.user.shows &&  this.user.shows.length > 0
    },

    userHasGuest() {
      return this.user.guest
    },

    reviewRoute() {
      if (this.type == 'guest') {
        return {
          name: 'CreateGuestReview',
          params: {
            guestId: this.$route.params.guestId,
          }
        }
      }

      return {
        name: 'CreateShowReview',
        params: {
          showId: this.$route.params.showId,
        }
      }
    },

    textColor() {
      return `color: ${this.starColor};`
    },

    guestImage() {
      const defaultImage = require(`@/assets/images/test_image.png`);
      return this.profileImg ? this.profileImg : defaultImage
    },
  },

  methods: {
    openAllReview() {
      this.allReview=!this.allReview;
    }
  },
}
</script>
