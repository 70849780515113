<template>
  <button v-if="authenticated"
          class="rounded-full text-violet flex justify-center items-center bg-white uppercase font-semibold text-sm"
          :class="size == 'large' ? 'px-8 py-3 ml-3 lg:ml-0' : 'p-2'"
          @click.prevent="favorite"
  >
    <template v-if="loading">
      <loading-icon class="h-5 w-5 text-pink-500"/>
    </template>
    <template v-else>
      <HeartIcon :class="heartClass" :fill="isFavorited" :color="color"/>
      <span v-if="size == 'large'">{{ text }}</span>
    </template>
  </button>

  <modal v-else :class="size == 'large' ? 'px-8 py-3 ml-3 lg:ml-0' : 'p-2'"
         class="rounded-full text-violet flex justify-center items-center bg-white uppercase font-semibold text-sm">
    <template v-if="loading">
      <loading-icon class="h-5 w-5 text-pink-500"/>
    </template>
    <template v-else>
      <HeartIcon :class="heartClass" :fill="false" :color="color"/>
      <span v-if="size == 'large'">{{ text }}</span>
      <div slot="content">
        <LoginModal/>
      </div>
    </template>
  </modal>
</template>

<script>
import HeartIcon from "@/components/svgs/HeartIcon";
import Modal from '@/components/shared/Modal';
import LoginModal from '@/components/shared/LoginModal';

export default {
  name: "FavoriteButton",

  components: {HeartIcon, Modal, LoginModal},

  props: {
    isFavorited: Boolean,
    loading: Boolean,
    color: { type: String, required: false, default: '#B503D0' },
    size: { type: String, required: false, default: 'large' },
    text: { type: String, requred: false, default: 'Favorite'},
  },


  methods: {
    favorite() {
      this.$emit('toggleFavorite')
    }
  },

  computed: {
    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },

    heartClass() {
      return this.size == 'large' ? 'w-5 mr-3' : 'w-5 h-5'
    },
  },
};
</script>
