<template>
  <div class="flex flex-col flex-start p-6 pt-5 bg-white rounded-lg">
    <div id="fb-root"></div>
    <span class="font-bold mb-5">Share</span>
    <div class="flex justify-start">
      
      <div @click="shareOnFacebook(profileUrl, 520, 350)" class="social-network fb-xfbml-parse-ignore">
        <RoundedFacebookIcon class="text-blue-700"/>
        <span class="text-sm">Facebook</span>
      </div>
    
      <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="social-network" data-show-count="false">
        <RoundedTwitterIcon class="text-blue-400"/>
        <span class="text-sm">Twitter</span>
      </a>

      <div @click="shareOnLinkedin(profileUrl, 520, 350)" class="social-network">
        <RoundedLinkedinIcon class="text-blue-600"/>
        <span class="text-sm">Linkedin</span>
      </div>
    </div>
    <button
      class="rounded-lg flex group"
      ref="btnRef"
      v-on:mouseenter="mouseEnter()"
      v-on:mouseleave="mouseLeave()"
      style="transition:all .15s ease"
      v-clipboard:copy="profileUrl"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      <span class="px-4 py-2 bg-gray-50 text-blue-800 shadow-sm text-sm inline-flex rounded-lg rounded-r-none group-hover:bg-gray-300 transition duration-150 ease-in-out w-full">
        {{copyMessage | truncate(40)}}
      </span>
      <div class="bg-gray-300 flex flex-col items-center rounded-l-none rounded-lg p-2 group-hover:bg-gray-50">
        <CopyIcon/>
      </div>
    </button>
  </div>
</template>

<script>
  import RoundedFacebookIcon from '@/components/svgs/RoundedFacebookIcon';
  import RoundedTwitterIcon from '@/components/svgs/RoundedTwitterIcon';
  import RoundedLinkedinIcon from '@/components/svgs/RoundedLinkedinIcon';
  import CopyIcon from "@/components/svgs/CopyIcon";

  export default {
    name: 'ShareProfileModal',

    components: { RoundedFacebookIcon, RoundedTwitterIcon, RoundedLinkedinIcon, CopyIcon },

    props: {
      profileUrl: String
    },

    data() {
      return {
        copyMessage: this.profileUrl,
        tooltipShow: false
      }
    },

    mounted() {
      this.shareScripts();
    },

    methods: {
      onCopy() {
        this.copyMessage = 'Copied!'
      },
      onError() {
        alert(`Failed to copy. Referral link is: ${this.profileUrl}`)
      },

      mouseEnter(){
        this.copyMessage = 'Copy to clipboard'
      },

      mouseLeave() {
        this.copyMessage = this.profileUrl
      },

      shareScripts() {
        (function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0"; fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));
        (function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js, fjs);});
      },

      shareOnFacebook(url, winWidth, winHeight) {
        this.openPopup(`https://www.facebook.com/sharer/sharer.php?u=${this.profileUrl}src=sdkpreparse`, winWidth, winHeight)
      },

      shareOnLinkedin(url, winWidth, winHeight) {
        this.openPopup(`http://www.linkedin.com/shareArticle?mini=true&url=${this.profileUrl}`, winWidth, winHeight)
      },

      openPopup(url, winWidth, winHeight) {
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        window.open(url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight);
      }
    },

    destroyed() {
      window.Event.$off('openModal')
    }
  }
</script>


<style lang="scss" scoped>
  .social-network {
    @apply flex flex-col items-center m-3 cursor-pointer;

    span{
      @apply text-xs mt-1;
    }
  }
</style>
