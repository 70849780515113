<template>
  <a v-if="link" :href="link">
    <component :is="icon" :classes="classes"/>
  </a>
</template>

<script>
import {startCase} from 'lodash';
import RoundedFacebookIcon from "@/components/svgs/RoundedFacebookIcon";
import RoundedTwitterIcon from "@/components/svgs/RoundedTwitterIcon";
import RoundedYoutubeIcon from "@/components/svgs/RoundedYoutubeIcon";
import RoundedInstagramIcon from "@/components/svgs/RoundedInstagramIcon";
import RoundedLinkedinIcon from "@/components/svgs/RoundedLinkedinIcon";
import RoundedWikipediaIcon from "@/components/svgs/RoundedWikipediaIcon";
import RoundedWebsiteIcon from "@/components/svgs/RoundedWebsiteIcon";
  export default {
    name: 'SocialIconLink',
    props: {
      link: String,
      socialNetwork: String,
      classes: String
    },

    components: {
      RoundedFacebookIcon,
      RoundedTwitterIcon,
      RoundedYoutubeIcon,
      RoundedInstagramIcon,
      RoundedLinkedinIcon,
      RoundedWikipediaIcon,
      RoundedWebsiteIcon
    },

    computed: {
      icon() {
        return `Rounded${startCase(this.socialNetwork)}Icon`
      }
    },
  }
</script>
