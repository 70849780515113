<template>
  <button @click.prevent="goToMediaKit" class="media-kit-btn mb-12">
    <span class="mr-2.5">See Full Media Kit</span>
    <MediaKitIcon />
  </button>
</template>

<script>
import MediaKitIcon from "@/components/svgs/MediaKitIcon";

export default {
  name: "MediaKit",
  components: { MediaKitIcon },
  props: {
    url: { type: String, required: false }
  },

  methods: {
    goToMediaKit() {
      window.open(this.url, '_blank');
    }
  }
};
</script>

<style scoped>
.media-kit-btn {
  @apply rounded-full text-white text-sm px-7 py-2.5 flex justify-center items-center;
  background: linear-gradient(91.66deg, #F74C3E 3.23%, #C907DD 96.39%);
}
</style>
