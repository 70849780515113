<template>
  <svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.9106 50.7379C39.8492 50.7379 51.1487 39.4384 51.1487 25.4998C51.1487 11.5612 39.8492 0.261719 25.9106 0.261719C11.972 0.261719 0.672485 11.5612 0.672485 25.4998C0.672485 39.4384 11.972 50.7379 25.9106 50.7379ZM27.8687 21.6623C28.6378 20.3658 30.3653 19.25 32.89 19.25C38.0142 19.25 39.2303 22.0203 39.2303 27.1037V36.5181H34.0425V28.2613L34.0385 27.8909C33.9801 25.2276 33.2713 23.7338 31.582 23.7338C29.1325 23.7338 28.1135 25.4939 28.1135 28.2613V36.5181H22.9256V19.4723H27.7429V21.8928L27.8687 21.6623ZM19.2199 19.25H14.032V36.2958H19.2199V19.25ZM13.2914 13.6915C13.2914 11.8498 14.7848 10.3564 16.6265 10.3564C18.4682 10.3564 19.9616 11.8498 19.9616 13.6915C19.9616 15.5332 18.4682 17.0265 16.6265 17.0265C14.7848 17.0265 13.2914 15.5332 13.2914 13.6915Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ProfileLinkedinIcon",
  props: {
    classes: {type: String, default: 'w-12 h-12'}
  }
};
</script>
