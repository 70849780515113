<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9945 3.56249L15.9998 3.55107C15.999 3.6961 15.9986 3.86989 15.9963 3.98371C16.3357 3.99166 17.2043 4.15138 17.4766 4.86632L13.6502 13.6981C13.4627 13.2598 11.6832 9.30691 11.4801 8.7986L13.3305 4.96015C13.7126 4.37616 14.4432 3.99962 15.1496 3.97229L15.1476 3.57086L11.3044 3.56739C11.2953 3.66917 11.3046 3.88315 11.2906 3.98942C12.3747 4.01879 12.7623 4.26153 12.5024 4.90895C12.0736 5.88315 11.1745 7.72874 10.9987 8.0655C10.4488 6.91609 10.0991 6.03144 9.50046 4.78881C9.24222 4.21298 9.81234 3.98881 10.4153 3.97208V3.568L5.96451 3.55434C5.96777 3.74403 5.96022 3.85031 5.96022 3.98901C6.58052 3.99839 7.38562 4.06469 7.70668 4.60482C8.13238 5.43358 9.55145 8.72394 9.98083 9.60513C9.43315 10.6562 8.1028 13.1423 7.62611 14.0749C6.94319 12.5593 4.41713 6.59115 3.70321 5.05072C3.43723 4.39432 4.46446 4.01512 5.05885 3.98738L5.06272 3.54924L0 3.54883C0.00101989 3.6806 0.00550739 3.89763 0.00591535 3.99493C0.826925 3.98738 2.02692 5.33567 2.36961 6.17402C3.84722 9.60309 5.37195 13.0122 6.84243 16.4441C6.84283 16.45 7.3026 16.4561 7.3077 16.4457C8.26986 14.4767 9.39419 12.4076 10.4094 10.4161L12.9705 16.4488C12.9715 16.4496 13.3952 16.452 13.3974 16.4502C15.0215 12.7386 17.455 7.04317 18.2509 5.24511C18.5748 4.47672 19.1168 4.00819 19.9935 3.98167L20 3.58269L19.9945 3.56249Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "WikipediaIcon"
};
</script>

<style scoped></style>
