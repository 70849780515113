<template>
  <svg
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M25.7991 0.262695C11.8626 0.262695 0.561035 11.5643 0.561035 25.5008C0.561035 39.4373 11.8626 50.7389 25.7991 50.7389C39.7356 50.7389 51.0372 39.4373 51.0372 25.5008C51.0372 11.5643 39.7356 0.262695 25.7991 0.262695ZM37.3226 19.9407C37.3337 20.1891 37.3391 20.4386 37.3391 20.6893C37.3391 28.344 31.5125 37.1709 20.8571 37.1713H20.8575H20.8571C17.5857 37.1713 14.5414 36.2124 11.9778 34.5692C12.4311 34.6227 12.8924 34.6493 13.3595 34.6493C16.0737 34.6493 18.5715 33.7235 20.5544 32.1696C18.0185 32.1226 15.8804 30.4478 15.1426 28.1461C15.4957 28.2138 15.8589 28.2508 16.2313 28.2508C16.76 28.2508 17.2722 28.1796 17.759 28.0467C15.1083 27.516 13.1115 25.1735 13.1115 22.3684C13.1115 22.3422 13.1115 22.3187 13.1123 22.2944C13.8929 22.7284 14.7856 22.9895 15.736 23.0188C14.1806 21.981 13.1581 20.2068 13.1581 18.1969C13.1581 17.1356 13.445 16.1413 13.9426 15.2852C16.7993 18.7904 21.0685 21.0956 25.8831 21.3378C25.7837 20.9134 25.7325 20.4713 25.7325 20.0169C25.7325 16.819 28.3269 14.2246 31.526 14.2246C33.1923 14.2246 34.6973 14.9289 35.7544 16.055C37.0742 15.7947 38.3134 15.3125 39.4329 14.649C38.9997 16.0011 38.0816 17.1356 36.8855 17.853C38.0573 17.7129 39.1741 17.4021 40.212 16.9407C39.4368 18.1026 38.4536 19.1231 37.3226 19.9407Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="50.4762"
          height="50.4762"
          fill="currentColor"
          transform="translate(0.561035 0.261719)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ProfileTwitterIcon",
  props: {
    classes: {type: String, default: 'w-12 h-12'}
  }
};
</script>
