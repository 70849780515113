<template>
  <svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M22.6929 30.2281L30.9025 25.4998L22.6929 20.7715V30.2281Z"
        fill="currentColor"
      />
      <path
        d="M25.8361 0.262695C11.8996 0.262695 0.598022 11.5643 0.598022 25.5008C0.598022 39.4373 11.8996 50.7389 25.8361 50.7389C39.7726 50.7389 51.0742 39.4373 51.0742 25.5008C51.0742 11.5643 39.7726 0.262695 25.8361 0.262695ZM41.6061 25.5266C41.6061 25.5266 41.6061 30.645 40.9568 33.1131C40.5929 34.4641 39.5277 35.5293 38.1767 35.8928C35.7086 36.5425 25.8361 36.5425 25.8361 36.5425C25.8361 36.5425 15.9894 36.5425 13.4955 35.867C12.1446 35.5034 11.0794 34.4379 10.7154 33.0869C10.0658 30.645 10.0658 25.5008 10.0658 25.5008C10.0658 25.5008 10.0658 20.3828 10.7154 17.9146C11.079 16.5637 12.1704 15.4723 13.4955 15.1088C15.9636 14.4591 25.8361 14.4591 25.8361 14.4591C25.8361 14.4591 35.7086 14.4591 38.1767 15.1346C39.5277 15.4981 40.5929 16.5637 40.9568 17.9146C41.6323 20.3828 41.6061 25.5266 41.6061 25.5266Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="50.4762"
          height="50.4762"
          fill="currentColor"
          transform="translate(0.598145 0.261719)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ProfileYoutubeIcon",
  props: {
    classes: {type: String, default: 'w-12 h-12'}
  }
};
</script>
