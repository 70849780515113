<template>
  <div class="flex flex-wrap">
    <span v-for="tag in tags.slice(0, 8)"
      :key="`tag-${tag.value}`"
      class="mb-3"
    >
      <router-link :to="redirectTo(tag.name)" class="rounded-full border-2 text-grayHome border-gray-300 text-sm px-4 py-1 mr-3 font-roboto leading-5 hover:bg-lightGray hover:text-white hover:border-lightBlack">
        {{ tag.name }}
      </router-link>
    </span>
  </div>
</template>

<script>

export default {
  name: 'Tags',
  props: {
    tags: Array,
    type: String
  },

  methods: {
    redirectTo(tag) {
        if (! this.type) {
          return {
            name: 'Home'
          }
        }

        return {
          name: 'Browse',
          query: {
            showing: this.type,
            tags: tag,
          }
        }
      },
  }
}
</script>
