<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9297 6.0257C20.7652 5.94067 20.605 5.84746 20.4498 5.74641C19.9985 5.44804 19.5847 5.09648 19.2174 4.6993C18.2982 3.64762 17.9549 2.5807 17.8285 1.83371H17.8336C17.7279 1.21367 17.7716 0.8125 17.7782 0.8125H13.5918V17.0005C13.5918 17.2179 13.5918 17.4327 13.5827 17.645C13.5827 17.6714 13.5801 17.6957 13.5786 17.7242C13.5786 17.7359 13.5786 17.748 13.5761 17.7602C13.5761 17.7633 13.5761 17.7663 13.5761 17.7694C13.5319 18.3502 13.3458 18.9113 13.0339 19.4033C12.722 19.8952 12.294 20.303 11.7876 20.5908C11.2597 20.8911 10.6627 21.0486 10.0554 21.0478C8.1049 21.0478 6.52408 19.4573 6.52408 17.4931C6.52408 15.5289 8.1049 13.9384 10.0554 13.9384C10.4246 13.9381 10.7916 13.9962 11.1426 14.1106L11.1477 9.84801C10.082 9.71035 8.99932 9.79504 7.96798 10.0968C6.93664 10.3985 5.97902 10.9106 5.15553 11.601C4.43396 12.2279 3.82733 12.976 3.36295 13.8115C3.18623 14.1162 2.51947 15.3405 2.43873 17.3276C2.38795 18.4554 2.72666 19.6239 2.88814 20.1068V20.117C2.98971 20.4014 3.38326 21.3718 4.02463 22.1899C4.5418 22.8461 5.15283 23.4226 5.83803 23.9007V23.8905L5.84818 23.9007C7.87486 25.2779 10.1219 25.1875 10.1219 25.1875C10.5109 25.1718 11.814 25.1875 13.2937 24.4862C14.935 23.7087 15.8694 22.5504 15.8694 22.5504C16.4663 21.8583 16.9409 21.0696 17.2729 20.218C17.6518 19.2222 17.7782 18.0278 17.7782 17.5505V8.96238C17.829 8.99285 18.5054 9.44023 18.5054 9.44023C18.5054 9.44023 19.4799 10.0648 21.0003 10.4716C22.0911 10.7611 23.5607 10.822 23.5607 10.822V6.66605C23.0458 6.72191 22.0002 6.55941 20.9297 6.0257Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "TiktokIcon"
};
</script>

<style scoped></style>
