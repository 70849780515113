<template>
  <modal class="transition duration-300 ease-in-out hover:bg-white hover:text-black flex items-center text-white uppercase border-white border rounded-full px-8 py-2 text-xs font-medium justify-center ml-10">
    <ShareIcon class="mr-3"/>
    <span>Share profile</span>
    <div slot="content" class="w-4/12">
      <ShareProfileModal :profileUrl="profileUrl"/>
    </div>
  </modal>
</template>

<script>
import ShareIcon from "@/components/svgs/ShareIcon";
import Modal from '@/components/shared/Modal';
import ShareProfileModal from '@/components/shared/ShareProfileModal';

export default {
  name: "ShareProfileButton",

  components: {ShareIcon, Modal, ShareProfileModal},

  props: {
    profileUrl: String
  },
};
</script>
