<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6004 6.11914C11.8485 6.11914 10.6498 6.8869 10.116 7.77892L10.0288 7.9375V6.27212H6.68604V18.0002H10.2859V12.3192C10.2859 10.4152 10.993 9.20413 12.6927 9.20413C13.8649 9.20413 14.3568 10.2319 14.3973 12.0644L14.4001 12.3192V18.0002H18V11.5227C18 8.0252 17.156 6.11914 13.6004 6.11914Z"
      fill="#0274B3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.11405 6.11914H0.51416V17.8472H4.11405V6.11914Z"
      fill="#0274B3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.31421 0C1.03625 0 0 1.02748 0 2.29462C0 3.56175 1.03625 4.58923 2.31421 4.58923C3.59217 4.58923 4.62842 3.56175 4.62842 2.29462C4.62842 1.02748 3.59217 0 2.31421 0Z"
      fill="#0274B3"
    />
  </svg>
</template>

<script>
export default {
  name: "LinkedinIcon"
};
</script>

<style scoped></style>
