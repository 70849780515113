<template>
  <a class="w-full font-roboto bg-red-200 rounded-lg" :href="article.url" target="_blank">
    <div :style="backgroundImage" class="h-56 bg-cover bg-no-repeat rounded-t-lg"></div>
    <div class="bg-ultraSoftGray p-3 rounded-b-lg">
      <span class="uppercase text-xs">{{articleUrl}}</span>
      <h4 class="font-bold capitalize text-gray-800">{{article.title | truncate(70)}}</h4>
      <p class="text-sm">{{article.description | truncate(50)}}</p>
    </div>
  </a>
</template>

<script>

  export default {
    name: "Article",
    props: {
      article: Object,
    },

    computed: {
      backgroundImage() {
        const defaultImage = require('@/assets/images/test_image.png');
        return `background-image:url('${this.article.imageUrl ? this.article.imageUrl : defaultImage }')`
      },

      articleUrl(){
        return this.article.url.split('//')[1].split('/')[0]
      }
    },

  };
</script>
