<template>
  <div v-if="ratings.length" class="grid gap-6" :class="withReviewCount ? 'grid-cols-2' : 'grid-cols-1'">
    <div class="flex items-end" :class="marginBottom">
      <ProfileRating :rating="avgRating" :starSize="starSize" :activeColor="color" :inactiveColor="inactiveColor" :padding="0" :borderWidth="2"/>
      <span v-if="withReviewCount" class="text-gray-300 font-light ml-3 leading-3 flex-shrink-0">{{reviewsText}}</span>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import ProfileRating from "@/components/shared/ProfileRating";

export default {
  name: 'Rating',
  props: {
    ratings: Array,
    avgRating: Number,
    marginBottom:{
      type:String,
      default:'mb-5'
    },
    starSize: {
      type: Number,
      default: 18,
    },
    color: {
      type: String,
      default: '#B503D0'
    },
    inactiveColor: {
      required: false,
      type: String,
      default: '#413F51'
    },
    withReviewCount: {
      type: Boolean,
      default: true,
    }
  },

  components: {ProfileRating},

  computed: {
    reviewsText() {
      return `${this.ratings.length} ${pluralize('Review', this.ratings.length)}`
    },
  },
}
</script>
