<template>
  <svg
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
  >
    <path
      d="M50.4762 25.2381C50.4762 11.298 39.1782 0 25.2381 0C11.298 0 0 11.298 0 25.2381C0 39.1782 11.298 50.4762 25.2381 50.4762C25.386 50.4762 25.5339 50.4762 25.6817 50.4663V30.8279H20.2595V24.5086H25.6817V19.8553C25.6817 14.4626 28.9745 11.5247 33.7855 11.5247C36.0924 11.5247 38.074 11.6923 38.6458 11.7712V17.4103H35.3333C32.7208 17.4103 32.2081 18.6525 32.2081 20.4764V24.4987H38.4684L37.6501 30.8181H32.2081V49.5002C42.7569 46.4736 50.4762 36.7628 50.4762 25.2381V25.2381Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ProfileFacebookIcon",
  props: {
    classes: {type: String, default: 'w-12 h-12'}
  }
};
</script>
